import React from 'react';
import './styles/Leadership.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
function Leadership() {
    return (
        <div id="Leadership" className="leadership-section">
            <div className="leadership-header">
                <div className="section-title">Our Leadership</div>
                <div className="sub-title">
                    Air Force Life Cycle Management | Enterprise Cloud Services Branch Hanscom AFB
                </div>
            </div>
            <div className='all-circles'>
                <div className='circle-group-1'>
                    <div className="circle-container">
                    <Box sx={{ flexGrow: 1 }}>
      <Grid container >
          <Grid item xs={12} sm={12} md={6} lg={6} key={2} >
          <div className="Lead-circle light-blue">
                            <div className="initials">MK</div>
                        </div>
                        <div className="info-container">
                            <div className="info-box">
                                <div className="name">Matthew Kauffmann, Lt Col</div>
                                <div className="work-title">Materiel Leader, Compute and Store</div>
                                <p className="title-end">AFLCMC/HNII</p>
                            </div>
                        </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} key={2}>
          <div className="Lead-circle dark-grey">
                            <div className="initials">JM</div>
                        </div>
                        <div className="info-container">
                            <div className="info-box">
                                <div className="name">James McGovern, Civ</div>
                                <div className="work-title">Enterprise Cloud Service Branch Lead Engineer</div>
                                <p className="title-end">AFLCMC/HNII</p>
                            </div>
                        </div>
          </Grid>
          </Grid>
          </Box>
                    </div>
                </div>
                <br />
                <div className='circle-group-2'>
                    <div className="circle-container">
                    <Box sx={{ flexGrow: 1 }}>
      <Grid container >
          <Grid item xs={12} sm={12} md={6} lg={6} key={2}>
          <div className="Lead-circle dark-grey">
                            <div className="initials">JT</div>
                        </div>
                        <div className="info-container">
                            <div className="info-box">
                                <div className="name">John Zelinski, Civ</div>
                                <div className="work-title">Enterprise Cloud Service Program Manager (Acting)  &nbsp;   &nbsp;  </div>
                                <p className="title-end">AFLCMC/HNII</p>
                            </div>
                        </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} key={2}>
          <div className="Lead-circle light-blue right-circle">
                            <div className="initials">RB</div>
                        </div>
                        <div className="info-container">
                            <div className="info-box">
                                <div className="name">Ryan Boudreaux, Capt</div>
                                <div className="work-title">Cloud One Kickstart (C1K) Project Manager</div>
                                <p className="title-end">AFLCMC/HNII</p>
                            </div>
                        </div>
          </Grid>
          </Grid>
          </Box>
                       
                        
                    </div>
                </div>
                <br />
                <div className='circle-group-2'>
                    <div className="circle-container">
                    <Box sx={{ flexGrow: 1 }}>
      <Grid container >
          <Grid item xs={12} sm={12} md={6} lg={6} key={2}>
          <div className="Lead-circle light-blue">
                            <div className="initials">JC</div>
                        </div>
                        <div className="info-container">
                            <div className="info-box">
                                <div className="name">James Crocker, Civ</div>
                                <div className="work-title">Enterprise Cloud Chief Technology Officer</div>
                                <p className="title-end">AFLCMC/HNII</p>
                            </div>
                        </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} key={2}>
          <div className="Lead-circle dark-grey right-circle">
                            <div className="initials">BB</div>
                        </div>
                        <div className="info-container">
                            <div className="info-box">
                                <div className="name">Beau Brantley, Lt Col</div>
                                <div className="work-title">Cloud One Lead Engineer</div>
                                <p className="title-end">AFLCMC/HNII</p>
                            </div>
                        </div>
          </Grid>
          </Grid>
          </Box>
                       
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Leadership;